import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class BaseAPIService {
  private baseURL: string;

  constructor(private httpClient: HttpClient) {
    this.baseURL = environment.documentConnectBaseURL;
  }

  protected get<T>(endpoint: string): Observable<T> {
    const headers = this.getHeaders();
    return this.httpClient.get<T>(`${this.baseURL}/${endpoint}`, { headers });
  }

  protected post<T>(
    endpoint: string,
    data: any,
    options: any = {}
  ): Observable<T> {
    const requestOptions = {
      ...options,
      observe: 'response',
    };

    return this.httpClient.post<T>(
      `${this.baseURL}/${endpoint}`,
      data,
      requestOptions as {}
    );
  }

  /**
   * Utility method to handle HTTP errors.
   * @param operation The operation being attempted (for logging purposes).
   * @param result The optional default result to return in case of an error.
   * @returns A function that handles the error and returns a safe result.
   */
  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T); // Return an empty result to keep the app running
    };
  }

  //TODO: Implement the key returned by the JWT
  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }
}
